html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .main-content {
    flex: 1;
    position: relative;
    min-height: 100vh; /* Assurez-vous que le conteneur parent prend toute la hauteur de la page */

    /* other styling for main content */
  }
  
  .Footer-ensemble {
    position: relative;
    margin-top: 35px;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(249, 250, 220) !important;
    font-family: 'Urbanist', sans-serif;
    align-items: center;
    z-index: 1000; /* Assurez-vous que le footer soit au-dessus des autres éléments */
    opacity: 0; /* Initialement caché */
    transform: translateY(150%); /* Initialement en dehors de l'écran */
}

.Footer-ensemble a {
    text-decoration: none;
    color: black;
}

.Footer-ensemble.slide-up {
    animation: slideUp 1.4s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
}

@keyframes slideUp {
    from {
        transform: translateY(150%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.Back--top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 3px;
  transform: translateY(-50%);
    background-color: blueviolet;
    color: white;
    font-size: 1.5rem;
    padding: 10px 20px;
    border-radius: 25%;
    cursor: pointer;
    display: flex;
    width: 5px;
    height: 20px;
}
.Back--top:hover {
  background-color: white;
}

.Back--top:hover .top-arrow {
  color: blueviolet;
}

.back-to-top {
    display: flex;
}
.top-arrow {

    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}

  
.droite-footer, .milieu-footer, .bas-footer {
    width: 33.33%; /* Divide space into thirds */
    height: auto; /* Adjust height as needed */
  }
  
  
  .milieu-footer {

    padding-bottom: 23px;
    line-height: 2em;
  }
  
  
  .milieu-footer img::after {
    content: '';
    display: block;
    position: relative;
    border-bottom: 2px solid blueviolet;
    width: 80%;
    height: 2px;
    margin: 0 auto;
    top: 10px; /* Ajustez cette valeur selon vos besoins */
    pointer-events: none;
    box-sizing: border-box;
}

.milieu-footer img {
    max-width: 100px;
    z-index: -2;
    margin: 0 auto;
    display: block;
    padding-top: 15px;
    position: relative; /* Assurez-vous que l'image est positionnée relativement pour que ::after fonctionne correctement */
}

  
  .milieu-footer img:hover {
    transition: transform 0.6s ease;
    transform: scale(1.2);
  }
  
  .milieu-footer a {
    display: block;
    margin-top: 10px;
  }
  .logo--voxiplan {
    display: inline-flex;
    align-items: flex-start;
    font-size: 0.5em; 
    transform: translateY(-10px);

  }
  
  .droite-footer {
    right: 0;
    bottom: 50px;
    line-height: 2em;
  }
  
  .droite-footer p,
  .milieu-footer p {
    margin: 0;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
  
  .droite-footer input, button {
    margin-top: 10px;
    color: black;
    font-size: 1.33rem;
    font-weight: bold;
    text-align: center;
  }
  
  .droite-footer input:hover,
  .droite-footer p:hover,
  .droite-footer input:active,
  .droite-footer input:focus,
  .droite-footer a:hover,
  .gauche-footer a:hover,
  .droite-footer button:hover,
  .bas-footer p:hover,
  .milieu-footer p:hover {
    color: blueviolet;
    cursor: pointer;
  }
  
  .droite-footer button,
  .droite-footer p,
  .droite-footer a,
  .gauche-footer p,
  .milieu-footer img {
    transition: transform 0.6s ease;
    transform: scale(1);
  }
  
  .droite-footer button:hover,
  .droite-footer button:active,
  .droite-footer p:hover,
  .droite-footer a:hover,
  .gauche-footer p:hover,
  .milieu-footer img:hover {
    transform: scale(1.2);
  }


  
  .News--form {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  

  
  .bas-footer {
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: black;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .bas-footer p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  #copyright {
    margin-bottom: 3px;
    margin-right: 5px;
    font-size: 1.2rem;
  }
  
  .svg-footer-social {
    display: flex;
    gap: 25px;
    justify-content: center;
    align-items: center;
    height: 200%;
    width: 100%;
    font-size: 200%;
    padding-top: 15px;
    color: black;
  }
  
  .svg-footer-social .fa-svg-icon {
    font-size: 4rem;
    padding: 0 10px;
    cursor: pointer;
  }
  
  .fa:hover {
    color: blueviolet;
  }

  @media (max-width: 440px) {
    .News--form {
      flex-direction: column;
    }

  }
  