@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.intro--message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4em; /* Augmenter la taille du texte pour plus de visibilité */
  animation: fadeIn 1s ease-in-out;
  background: linear-gradient(
    90deg,
    violet,
    yellow,
    pink
  ); /* Utiliser les couleurs complémentaires */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: relative; /* Nécessaire pour le curseur clignotant */
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.intro--panel {
  display: flex;
  position: relative;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  z-index: 10000;
  padding: 20px;
}


@media (max-width: 640px) {
  .intro--panel {
    width: 100%; /* Définir une largeur fixe */
    height: 300px; /* Définir une hauteur automatique */
    padding: 20px; /* Ajouter un padding fixe */
    box-sizing: border-box; /* Inclure le padding dans la largeur et la hauteur */
  }
}
@media (max-width: 440px) {
  .intro--panel {
    width: 100%; /* Définir une largeur fixe */
    height: 400px; /* Définir une hauteur automatique */
    padding: 20px; /* Ajouter un padding fixe */
    box-sizing: border-box; /* Inclure le padding dans la largeur et la hauteur */
  }
}
@media (max-width: 340px) {
  .intro--message {
    font-size: 3.8em; /* Réduire la taille du texte pour plus de visibilité */
  }
}
