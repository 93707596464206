.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 300px; /* Ajuster selon vos besoins */
  overflow: hidden;
}

#carousel {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}

.carousel {
  display: flex;
  justify-content: center; /* Centre horizontalement les items du carousel */
  align-items: center; /* Centre verticalement les items du carousel */
  animation: scroll 30s linear infinite;
}



.carousel-item {
  min-width: 200px;
   /* Ajuster selon la taille des éléments du carousel */
  margin: 0 10px; /* Espace entre les éléments */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.item img {
  max-height: 150px;
  max-width: 150px; /* Ajuster la taille des images selon vos besoins */
  display: flex;
  margin: 0 auto;
  align-self: center;
  justify-content: center;
}
.carousel.left .carousel-item-enter {
  opacity: 0;
  transform: translateX(100%);
}

.carousel.left .carousel-item-enter-active {
  opacity: 1;
  transform: translateX(-100%);
  transition: opacity 1000ms ease-in, transform 1000ms ease-out;
}

.carousel.left .carousel-item-exit {
  opacity: 1;
  transform: translateX(0);
}

.carousel.left .carousel-item-exit-active {
  opacity: 0;
  transform: translateX(-180%);
  transition: opacity 1000ms ease-in, transform 1000ms ease-out;
}

.carousel.right .carousel-item-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.carousel.right .carousel-item-enter-active {
  opacity: 1;
  transform: translateX(100%);
  transition: opacity 1000ms ease-in, transform 1000ms ease-out;
}

.carousel.right .carousel-item-exit {
  opacity: 1;
  transform: translateX(0);
  
}

.carousel.right .carousel-item-exit-active {
  opacity: 0;
  transform: translateX(180%);
  transition: opacity 1000ms ease-in, transform 1000ms ease-out;
}


.item {
  position: relative;
}

.popup {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid blueviolet;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.item:hover .popup {
  display: block;
}

.popup .fa-star,
.popup .fa-star-half-alt,
.popup .fa-star-empty {
  color: blueviolet;
}