.contact--section {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.contact--panel {
    background-color: rgb(249, 250, 220);
    border: 2px solid blueviolet;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 70%;
    box-sizing: border-box;
    border-radius: 10px; /* Ajout du border-radius */
    margin-left: auto;
    margin-right: auto;
}

.contact--panel:hover {
    background-color: rgb(249, 250, 220);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s ease;
    transform: scale(0.98);
}

.contact--panel:not(:hover) {
    transition: transform 0.6s ease;
    transform: scale(1);
}

.contact--header {
    align-items: center;
    justify-content: center;
}
.contact--header p {
    font-family: 'Urbanist', sans-serif;
    font-size: 0.8em;
    text-align: center;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.contact--logo {
    font-size: 24px;
    color: blueviolet;
    margin-right: 10px;
}

.contact--message {
    font-family: 'Urbanist', sans-serif;
    font-size: 1.5em;
    position: relative;
    text-align: center;
}

.contact--message::after {
    content: "";
    display: block;
    width: 250px;
    height: 2px;
    background-color: blueviolet;
    margin: 10px auto 0;
}

.contact--form {
    display: flex;
    flex-direction: column;
    align-items: center;
}
fieldset {
    border: none;
    padding-top: 20px;
    margin: 0;
}
.red-asterisk {
    color: red;
}

.contact--row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.contact--row > div {
    width: 48%;
}

.contact--email,
.contact--textarea {
    width: 100%;
    margin-bottom: 15px;
    
}

.contact--textarea textarea {
    width: 100%; /* Pour s'assurer qu'il ne déborde pas */
    max-width: 100%; /* Empêche le textarea de dépasser le conteneur */
    box-sizing: border-box; /* Inclut le padding et la bordure dans la largeur totale */
    resize: vertical; 
    min-height: 80px;
}

.contact--form input,
.contact--form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Ajout du border-radius */
    box-sizing: border-box;
}

.contact--form button {
    padding: 10px 20px;
    background-color: blueviolet;
    color: white;
    border: none;
    border-radius: 5px; /* Ajout du border-radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact--form button:hover {
    background-color: darkviolet;
}

.contact--confirmation {
    font-family: 'Urbanist', sans-serif;
    font-size: 1em;
    text-align: center;
    margin-top: 10px;
}
#check {
    width: 20px;
}

#check:active {
    transform: scale(1.2);
}

input[type="checkbox"]:checked {
    background-color: blueviolet;
    color: blueviolet;
    border-color: blueviolet;
}

input:active,
input:focus,
textarea:active,
textarea:focus {
    color: blueviolet;
}

fieldset {
width: 80%;
}